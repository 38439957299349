import React from "react";
import KTHeader from "./components/KTHeader";
import SuitSherwani from "./components/SuitSherwani";
import "./styles/main.css";

export default function App() {
  return (
    <>
      <KTHeader
        title={"Kabadi Textiles"}
        desc={
          "BN Street, Opposite to Sri Nagraj Theatre, Near Auto Stand, Mandi Mohalla, Mysuru"
        }
      />
      <SuitSherwani />
    </>
  );
}
