import React from "react";
import { Link } from "react-router-dom";
import "../styles/suitSherwani.css";

export default function SuitSherwani() {
  return (
    <div className="suit-sherwani">
      <Link to="/suits">
        <div style={{ backgroundColor: "#ADB5FF" }}>
          <h3>Suits</h3>
          <img
            src={`${process.env.PUBLIC_URL}/images/landing/suit-square.png`}
            alt="suit"
          />
        </div>
      </Link>
      <Link to="/sherwanis">
        <div style={{ backgroundColor: "#FFDA92" }}>
          <img
            src={`${process.env.PUBLIC_URL}/images/landing/sherwani-square.png`}
            alt="suit"
          />
          <h3>Sherwanis</h3>
        </div>
      </Link>
    </div>
  );
}
