import React from "react";
import { Link } from "react-router-dom";
import Dress from "../components/Dress";
import suits from "../state/suits";
import KTHeader from "./KTHeader";
// import ReactDOM from "react-dom";

export default function Suits() {
  return (
    <>
      <KTHeader title="Suits" desc="" />
      <div className="dress-container">
        {suits.map((suit, index) => {
          return (
            <Link key={index} to={`/suits/${suit.name.toLowerCase()}`}>
              <Dress
                title={suit.name}
                imgUrl={`${process.env.PUBLIC_URL}/images/suits/${suit.imgUrls[0]}.png`}
                key={index}
                type="suit"
              />
            </Link>
          );
        })}
      </div>
    </>
  );
}
