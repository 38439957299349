import React from "react";
import petas from "../state/petas";
import sherwanis from "../state/sherwanis";
import Collections from "../components/Collections";
// import ReactDOM from "react-dom";

export default function Sherwanis() {
  return (
    <>
      <Collections array={sherwanis} desc={""} title={"Sherwanis"} />
      <Collections array={petas} desc={""} title={"Petas"} />
    </>
  );
}
