import React from "react";
import KTHeader from "./KTHeader";
import Dress from "../components/Dress";

export default function Collections({ title, desc, array }) {
  const formatTitle = (title) => {
    return title
      .split("-")
      .map((word) => `${word[0].toUpperCase()}${word.slice(1)}`)
      .join(" ");
  };

  let type;

  if (title === "Sherwanis") {
    type = "sherwanis";
  } else if (title === "Petas") {
    type = "petas";
  }

  return (
    <>
      <KTHeader title={title} desc={desc} />
      <div className="dress-container">
        {array.map((imgUrl) => {
          return (
            <Dress
              title={formatTitle(imgUrl)}
              imgUrl={`${process.env.PUBLIC_URL}/images/${
                type === undefined ? "suits" : type
              }/${imgUrl}.png`}
              key={imgUrl}
              type="suit"
            />
          );
        })}
      </div>
    </>
  );
}
