const sherwanis = [
  "premium-cross-leaf",
  "premium-feather",
  "premium-flourish",
  "premium-flower",
  "premium-peacock",
  "premium-petals",
  "royal",
  "royal-flourish",
  "royal-flower",
];

export default sherwanis;
