import React from "react";
import "../styles/dress.css";

export default function Dress({ imgUrl, title, type }) {
  return (
    <div data-aos="fade-up" className={`dress ${type}`}>
      <img src={imgUrl} alt={title} />
      <h3>{title}</h3>
    </div>
  );
}
