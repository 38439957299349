import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { HashRouter, Switch, Route } from "react-router-dom";
import App from "./App";
import Suits from "./components/Suits";
import Sherwanis from "./components/Sherwanis";
import suits from "./state/suits";
import Collections from "./components/Collections";
import AOS from "aos";
import "aos/dist/aos.css";
import ScrollToTop from "./components/ScrollToTop";

export default function Index() {
  useEffect(() => {
    AOS.init({
      delay: 150,
      offset: 150,
    });
  });

  return (
    <>
      <HashRouter basename={process.env.PUBLIC_URL}>
        <ScrollToTop />
        <Switch>
          <Route exact path="/">
            <App />
          </Route>
          <Route exact path="/suits">
            <Suits />
          </Route>
          <Route exact path="/sherwanis">
            <Sherwanis />
          </Route>
          <Route exact path="/suits/standard">
            <Collections
              array={suits[0].imgUrls}
              desc={""}
              title={"Standard Suits"}
            />
          </Route>
          <Route exact path="/suits/checker">
            <Collections
              array={suits[1].imgUrls}
              desc={""}
              title={"Checker Suits"}
            />
          </Route>
          <Route exact path="/suits/premium">
            <Collections
              array={suits[2].imgUrls}
              desc={""}
              title={"Premium Suits"}
            />
          </Route>
        </Switch>
      </HashRouter>
    </>
  );
}

ReactDOM.render(<Index />, document.getElementById("root"));
