const petas = [
  "golden",
  "majestic-golden",
  "premium-red",
  "red-beige-stripe",
  "red-ethnic",
  "red-white-stripe",
  "royal-red",
  "white-red-stripe",
];

export default petas;
