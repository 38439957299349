import React from "react";
import "../styles/header.css";

export default function KTHeader({ title, desc }) {
  const contactDetails = (
    <div data-aos="zoom-in" className="contact">
      <a href="https://api.whatsapp.com/send?phone=919341982565">
        <button>
          <img
            style={{ height: "1.5em", paddingRight: "0.5em" }}
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/WhatsApp_logo-color-vertical.svg/1024px-WhatsApp_logo-color-vertical.svg.png"
            alt="whatsapp"
          />
          9341982565
        </button>
      </a>
      <a href="https://goo.gl/maps/Gbmfhur7TTy6gvrp7">
        <button>
          <img
            style={{ height: "1.5em", paddingRight: "0.5em" }}
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/aa/Google_Maps_icon_%282020%29.svg/1200px-Google_Maps_icon_%282020%29.svg.png"
            alt="gmaps"
          />
          Location
        </button>
      </a>
    </div>
  );

  return (
    <header>
      <h1>{title}</h1>
      <h4>{desc}</h4>
      <>{title === "Kabadi Textiles" ? contactDetails : ""}</>
    </header>
  );
}
