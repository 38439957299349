const suits = [
  {
    name: "Standard",
    imgUrls: [
      "standard-biscuit",
      "standard-blue",
      "standard-green",
      "standard-orange",
      "standard-pink",
      "standard-sky-blue",
      "standard-yellow",
    ],
  },
  {
    name: "Checker",
    imgUrls: ["checker-black", "checker-bright-blue", "checker-navy-blue"],
  },
  {
    name: "Premium",
    imgUrls: [
      "premium-black",
      "premium-alt-black",
      "premium-checker-beige",
      "premium-checker-maroon",
      "premium-checker-navy-blue",
      "fancy-black",
    ],
  },
];

export default suits;
